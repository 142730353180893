.find{
    max-width: 1040px;
    margin: auto;
}

.heading{
    font-size: 2.5rem;
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    padding: 3rem;
}

.heading p{
    text-align: center;
}

.text_bg{
    background: rgba(173, 199, 255, 0.502);
    height: 30px;
    max-width: 650px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
}

.text_bg p{
    font-size: 1.4rem;
    margin-top: -.7rem;
}

.slider{
    padding: 5rem 0;
}

.card{
    margin: .5rem;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.card img{
    width: 100%;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
}