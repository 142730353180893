.luxury{
    max-width: 1040px;
    margin: auto;
    padding: 5rem 1rem;
}

.container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1rem;
    padding: 4rem 1rem;
}

.heading h2{
    font-size: 4rem;
    text-align: center;
    padding: 2rem;
}

.heading p{
    text-align: center;
}

.text_bg{
    background-color: rgba(171, 197, 255, 0.502);
    position: absolute;
    margin: auto;
    height: 30px;
    max-width: 650px;
    left: 0;
    right: 0;
}

.text_bg p{
    font-size: 1.4rem;
    margin-top: -0.6rem;
    font-weight: bold;
}

.card{
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card img{
    max-width: 100%;
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card .content{
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
}

@media screen and (max-width: 768px) {
    .luxury .container{
        grid-template-columns: repeat(2,1fr);
    }

    .heading h2{
        font-size: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .luxury .container{
        grid-template-columns: 1fr;
    }

    
}


