.driver{
    max-width: 1040px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    padding: 1rem;
    margin: auto;
}

.driver img{
    max-width: 512px;
}

.driver h2{
    padding-top: 30%;
}

.driver p{
    font-size: 1rem;
    padding: 1.5rem 0;
    color: black;
}

.driver button{
    background-color: #583cfb;
    border: none;
    padding: 12px 8px;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
}

.driver button:hover{
    background-color: #4733b7;
    transition: background-color 1s;
    cursor: pointer;
}

@media screen and (max-width: 920px) {
    .driver{
        grid-template-columns: 1fr;
    }

    .driver img{
        max-width: 80%;
       
    }

    .driver .left{
        display: flex;
        justify-content: center;
    }

    .driver h2{
        padding-top: 10%;
    }
}



