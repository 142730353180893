.footer{
    max-width: 100%;
    background-color: black;
    justify-content: space-between;
    display: flex;
    padding: 1rem;
    height: 80px;
}

.footer button{
    background-color: #583cfb;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    
}



.footer button:hover{
    cursor: pointer;
    background-color: #4733b7;
    transition: background-color 1s;
}

.footer img{
    height: 2rem;
    margin-top: 0.6rem;
}

@media screen and (max-width: 768px) {
    .footer button{
        font-size: 0.7rem;
        padding: 0.5rem 1rem;
    }
}