.hero{
    background: url('https://images.unsplash.com/photo-1519641471654-76ce0107ad1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80')
    no-repeat center/cover;
    width: 100%;
    height: 700px;
    display: flex;
}

.form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin-top: 10%;
    margin: auto;
    padding: 6px 15px;
    background-color: rgba(247, 247, 247, 0.9);
    box-shadow: rgba(0, 0, 0, 0.9) 0px 8px 24px;
    border-radius: 25px;
    border: none;
}

form label{
    font-size: .8rem;
    padding-bottom: 2px;
}

.text, .from, .until{
    display: flex;
    flex-direction: column;
}

form input{
    background-color: transparent;
    border: none;
    font-family: 'Poppins', sans-serif;
}

.from, .until{
    border-left: 1px solid #333;
    padding-left: 6px;
}

.search button{
    display: none;
}

form input:focus{
    outline: none;
}

form .text_input{
    width: 300px;
    font-size: 1rem;
}

.search{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 720px) {
    .form{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: auto 1rem;
        gap: 1rem;
    }

    .text, .from, .until{
        padding: .2rem;
        width: 100%;
    }

    .from, .until{
        border: none;
    }
    
    .text_input{
        font-size: 0.8rem;
    }

    .border{
        border-top: 1px solid #333;
        padding: 8px;
    }

    form label{
        padding: .4rem 0;
    }

    .search{
        border-top: 1px solid #333;
        width: 100%;
        padding: 8px 0;
    }

    .search .btn{
        display: block;
        background-color: #593cfb;
        color: white;
        width: 100%;
        font-weight: 600;
        font-size: 1rem;
        border: none;
        padding: 12px;
        margin: .5rem 0;
        border-radius: 5px;
        cursor: pointer;
    }

    .search .btn:hover{
        background-color: #a598ec;
        transition: background-color 1s;
    }

    .icon{
        display: none;
    }
}