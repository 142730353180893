.navbar{
    width: 100%;
    height: 70px;
    padding: 1rem;
    justify-content: space-between;
    position: relative;
    display: flex;
    align-items: center;
}

.links{
    display: flex;
    transition: 0.5s;
    align-items: center;
}

.links li{
    padding: 1rem;
}

.navbar a {
    font-size: .8rem;
}

.mobile_btn{
    display: none;
}

@media screen and (max-width: 720px) {
    .mobile_btn{
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        z-index: 10;
    }

    .links{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        justify-content: center;
        background-color: #f8f8f8;
        z-index: 10;
        transition: left 1s;
    }

    .active{
        left: 0;
    }

    .navbar a{
        font-size: 1.5rem;
    }
}